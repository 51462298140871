export interface FontSet {
  fontSize: number;
  fontWeight: number;
  lineHeight: number;
}

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  black: 900,
} as const;

export enum FontFamilyName {
  SANS_SERIF = 'ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", Helvetica, Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"',
  SANS_SERIF_NOTO = 'ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Apple Color Emoji", "Noto Color Emoji", "Twemoji Mozilla", Helvetica, Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"',
  MONOSPACE = 'iawriter-mono, Nitti, Menlo, Courier, monospace',
}

export const fontFamily = {
  // default: isTouchDevice() ? FontFamilyName.SANS_SERIF : FontFamilyName.SANS_SERIF_NOTO,
  default: FontFamilyName.SANS_SERIF,
  mono: FontFamilyName.MONOSPACE,
} as const;

export const font = {
  '11px': {
    fontSize: 11,
    lineHeight: 15,
    fontWeight: fontWeight.regular,
  },
  '12px': {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: fontWeight.regular,
  },
  'bold-11px': {
    fontSize: 11,
    lineHeight: 15,
    fontWeight: fontWeight.bold,
  },
  'black-11px': {
    fontSize: 11,
    lineHeight: 15,
    fontWeight: fontWeight.black,
  },
  '13px': {
    fontSize: 13,
    lineHeight: 17,
    fontWeight: fontWeight.regular,
  },
  '14px': {
    fontSize: 14,
    lineHeight: 18,
    fontWeight: fontWeight.regular,
  },
  '14px-21-lineHeight': {
    fontSize: 14,
    lineHeight: 21,
    fontWeight: fontWeight.regular,
  },
  'bold-13px': {
    fontSize: 13,
    lineHeight: 17,
    fontWeight: fontWeight.bold,
  },
  '15px': {
    fontSize: 15,
    lineHeight: 20,
    fontWeight: fontWeight.regular,
  },
  'bold-15px': {
    fontSize: 15,
    lineHeight: 20,
    fontWeight: fontWeight.bold,
  },
  '16px': {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: fontWeight.regular,
  },
  'semibold-16px': {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: fontWeight.semibold,
  },
  'semibold-14px': {
    fontSize: 14,
    lineHeight: 18,
    fontWeight: fontWeight.semibold,
  },
  'semibold-22px': {
    fontSize: 22,
    lineHeight: 26,
    fontWeight: fontWeight.semibold,
  },
  'semibold-20px': {
    fontSize: 22,
    lineHeight: 23,
    fontWeight: fontWeight.semibold,
  },
  'bold-16px': {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: fontWeight.bold,
  },
  'black-16px': {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: fontWeight.black,
  },
  '17px': {
    fontSize: 17,
    lineHeight: 23,
    fontWeight: fontWeight.regular,
  },
  '18px': {
    fontSize: 18,
    lineHeight: 23,
    fontWeight: fontWeight.regular,
  },
  '20px': {
    fontSize: 20,
    lineHeight: 23,
    fontWeight: fontWeight.regular,
  },
  'light-20px': {
    fontSize: 20,
    lineHeight: 23,
    fontWeight: fontWeight.light,
  },
  'bold-20px': {
    fontSize: 20,
    lineHeight: 23,
    fontWeight: fontWeight.bold,
  },
  'medium-11px': {
    fontSize: 11,
    lineHeight: 15,
    fontWeight: fontWeight.medium,
  },
  'medium-12px': {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: fontWeight.medium,
  },
  'medium-13px': {
    fontSize: 13,
    lineHeight: 17,
    fontWeight: fontWeight.medium,
  },
  'medium-14px': {
    fontSize: 14,
    lineHeight: 18,
    fontWeight: fontWeight.medium,
  },
  'medium-16px': {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: fontWeight.medium,
  },
  'medium-18px': {
    fontSize: 18,
    lineHeight: 23,
    fontWeight: fontWeight.medium,
  },
  'black-13px': {
    fontSize: 13,
    lineHeight: 17,
    fontWeight: fontWeight.black,
  },
  'black-18px': {
    fontSize: 18,
    lineHeight: 23,
    fontWeight: fontWeight.black,
  },
  'black-19px': {
    fontSize: 19,
    lineHeight: 23,
    fontWeight: fontWeight.black,
  },
  'black-22px': {
    fontSize: 22,
    lineHeight: 26,
    fontWeight: fontWeight.black,
  },
  'black-30px': {
    fontSize: 30,
    lineHeight: 39,
    fontWeight: fontWeight.black,
  },
  'bold-36px': {
    fontSize: 36,
    lineHeight: 36,
    fontWeight: fontWeight.bold,
  },
} as const;
